<template>
  <div class="">
    <van-sticky>
      <van-search
        v-model="filters.roomCode"
        placeholder="请输入房间号"
        shape="round"
        background="#ffffff"
        @search="onSearch"
        @blur="onBlur"
      >
      </van-search>
    </van-sticky>
    <div style="margin-bottom: 70px">
      <div class="center" v-for="item in list" :key="item.index">
        <!-- @click="xiangqing(item.ID) <router-link :to="'/article/detail/' + item.RIId"> -->
        <div class="list">
          <div class="title">房号:{{ item.RoomCode }}</div>
          <div class="flex">
            <span
              >预定:
              <span v-if="item.PreRoom == 1">允许</span>
              <span v-if="item.PreRoom == 2">不允许</span>
            </span>
            <span>房型: {{ item.TypeName }}</span>
          </div>
          <div class="flex">
            <!-- <span>单价:{{item.UnitPrice}}</span>
            <span>房型:{{item.RoomType}}</span> -->
            <span>在住旅客数: {{ item.GuestNum }}</span>
            <span>在住旅客: {{ item.GuestName }}</span>
          </div>

          <!-- <div style="padding: 5px 20px 15px;">房间介绍:{{item.Introduce}}</div> -->

          <div class="Status" style="color: #02c08f" v-if="item.Status == 1">
            空闲
          </div>
          <div class="Status" style="color: #e6776f" v-if="item.Status == 2">
            在住
          </div>
          <div class="Status" style="color: #e22424" v-if="item.Status == 9">
            禁用
          </div>
          <div>
            <div
              style="
                display: inline-block;
                width: 100%;
                text-align: right;
                margin-bottom: 5px;
              "
              class="ContentB"
            >
              <span class="centertime" style="color: #333">
                <van-button
                  type="info"
                  style="
                    width: 80px;
                    font-size: 13px;
                    border-radius: 5px;
                    margin-right: 5px;
                  "
                  @click="updata(item)"
                  size="small"
                  >编辑</van-button
                >

                <van-button
                  type="danger"
                  v-if="item.Status == 1"
                  style="
                    width: 80px;
                    font-size: 13px;
                    border-radius: 5px;
                    margin-right: 5px;
                  "
                  @click="dangerClick(item)"
                  size="small"
                  >删除</van-button
                >
              </span>
            </div>
          </div>
        </div>

        <!-- </router-link> -->
      </div>
      <div v-if="list.length == 0">
        <van-empty description="暂无数据" />
      </div>
    </div>
    <div
      style="
        position: fixed;
        bottom: 0px;
        width: 100%;
        padding: 10px 0;
        background-color: white;
      "
    >
      <div style="width: 100%; text-align: center">
        <van-button
          class="button"
          @click="addRoom()"
          style="width: 95%; border-radius: 5px"
          type="info"
          >添加房间</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
import { GetCodeEntrysList } from "@/api/wangge";
import { WxGetHotelRoomList, HDeleteHotelRoom } from "@/api/shop";
import { HGetMyRoomTypeList } from "@/api/RealInfo";
import { setOpenId, getOpenId } from "@/utils/auth";
import { Dialog } from "vant";
export default {
  data() {
    return {
      searchShow: false, //筛查添加弹窗
      inTimeShow: false, //入住时间弹窗
      leaveTimeShow: false, //退房时间弹窗
      nationPicker: false, //民族弹窗
      inTime: "", //入住时间范围
      leaveTime: "", //退房时间范围
      nationList: [], //民族列表
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(2026, 0, 31),
      filters: {
        roomCode: "",
        hotelId: "",
        page: 1,
        limit: 10,
      }, //搜索表单
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
      list: {},
      roomTypeList: [],
    };
  },
  mounted() {
    setTimeout(() => {
      this.getMyRoomTypeList();
    }, 500);
    this.zidianlist();
    this.filters.hotelId = this.$route.params.hotelId;
    this.getList(undefined);
  },

  methods: {
    // 获取房屋类型
    getMyRoomTypeList() {
      HGetMyRoomTypeList({
        openID: getOpenId(),
        hotelId: this.$route.params.hotelId,
      }).then((res) => {
        if (res.data.code == 0) {
          // console.log(res.data.data);
          this.roomTypeList = res.data.data;
        }
      });
    },

    onSearch(val) {
      this.filters.roomCode = val;
      this.filters.page = 1;
      this.list = {};
      this.getList(undefined);
    },
    onBlur(event) {
      this.filters.roomCode = event.target.value;
      this.filters.page = 1;
      this.list = {};
      this.getList(undefined);
    },
    // 字典查询
    zidianlist: function () {
      GetCodeEntrysList({ kind: "3" }).then((res) => {
        this.nationList = res.data.data;
      });
    },
    // 点击查看更多条件
    searchClick() {
      this.searchShow = true;
    },
    // 点击入住时间
    inTimeClick() {
      this.inTimeShow = true;
    },
    // 选中入住时间
    onInTime(event) {
      const [start, end] = event;
      this.inTimeShow = false;
      this.inTime = `${this.formatDate(start)}  ~  ${this.formatDate(end)}`;
      this.filters.startInTime = this.formatDate(start);
      this.filters.endInTime = this.formatDate(end);
    },
    // 点击退房时间
    leaveTimeClick() {
      this.leaveTimeShow = true;
    },
    // 选中退房时间
    onLeaveTime(event) {
      const [start, end] = event;
      this.leaveTimeShow = false;
      this.leaveTime = `${this.formatDate(start)}  ~  ${this.formatDate(end)}`;
      this.filters.startLeaveTime = this.formatDate(start);
      this.filters.endLeaveTime = this.formatDate(end);
    },
    //性别
    sexChange(val) {
      this.filters.sex = val;
    },
    // 退房
    leavedChange(val) {
      this.filters.leaved = val;
    },
    // 登记类型
    enrolCateChange(val) {
      this.filters.enrolCate = val;
    },
    // 民族确认
    nationQr(val) {
      this.filters.nationCode = val.Coding;
      this.filters.nationDesc = val.Name;
      this.nationPicker = false;
    },
    // 重置
    resetSubmit() {
      this.inTime = "";
      this.leaveTime = "";
      this.filters = {
        startInTime: "",
        endInTime: "",
        startLeaveTime: "",
        endLeaveTime: "",
        roomCode: "",
        name: "",
        sex: "",
        nationCode: "",
        nationDesc: "",
        cantonCode: "",
        mobile: "",
        leaved: "",
        enrolCate: "",
        page: 1,
        limit: 10,
      };
    },
    // 搜索
    filtersSubmit() {
      console.log(this.filters);
      this.getList();
    },
    // 获取旅客列表
    getList(row) {
      this.filters.openID = getOpenId();
      WxGetHotelRoomList(this.filters).then((res) => {
        if (res.data.code == 0) {
          if (res.data.count == 0) {
            this.finished = true;
            this.list = {};
          } else {
            this.total = res.data.count;
            this.finished = true;
            setTimeout(() => {
              this.list = res.data.data;
              for (let i = 0; i < res.data.data.length; i++) {
                const a = res.data.data[i];

                this.roomTypeList.forEach((e) => {
                  if (a.TypeId == e.TypeId) {
                    // console.log(e.Name);
                    this.list[i].TypeName = e.Name;
                  }
                });
              }
            }, 1000);
            // console.log(this.list);
          }
        }
      });
    },
    roomType(val) {
      // console.log(val);
      // console.log(this.roomTypeList);
      this.roomTypeList.forEach((e) => {
        if (val == e.TypeId) {
          let name = e.Name;
          console.log(name);
          return name;
        }
      });
    },
    // 添加房间
    addRoom(val) {
      var url =
        "/hotels/myHotelsList/roomList/addRoom/0/0/" +
        this.$route.params.hotelId;
      this.$router.push(url);
    },
    // 编辑
    updata(val) {
      var url =
        "/hotels/myHotelsList/roomList/addRoom/1/" +
        val.RoomId +
        "/" +
        this.$route.params.hotelId;
      this.$router.push(url);
    },
    // 删除
    dangerClick: function (val) {
      Dialog.confirm({
        title: "提示",
        message: "确定要删除该房间吗",
      })
        .then(() => {
          console.log("确认");
          var content = {
            HotelId: val.HotelId,
            RoomId: val.RoomId,
            OpenID: getOpenId(),
          };
          HDeleteHotelRoom(content)
            .then((res) => {
              if (res.data.code == 0) {
                Toast.success("操作成功");
                this.filters.page = 1;
                this.getList();
              } else {
                Toast.fail(res.data.msg);
              }
            })
            .catch(() => {});
        })
        .catch(() => {
          console.log("取消");
          // on cancel
        });
    },
    // 时间格式化
    formatDate(date) {
      var date = new Date(date);
      let year = date.getFullYear(); //年
      let month = date.getMonth() + 1; //月
      month = month.toString().padStart(2, "0"); // 当小于10时，显示为01.02.03
      let day = date.getDate(); //日
      day = day.toString().padStart(2, "0"); // 当小于10时，显示为01.02.03
      return year + "-" + month + "-" + day;
    },
  },
};
</script>
<style scoped>
.labelClass {
  font-size: 14px;
  font-weight: 500;
  width: 86px;
  color: #646566 !important;
}
.radio {
  font-size: 14px;
  color: #646566 !important;
}
.buttonStyle {
  width: 45%;
  border-radius: 8px;
  background: #1989fa;
  color: white;
  height: 35px;
  line-height: 35px;
  border: 0px solid;
}
.filtersBox {
  display: flex;
  border-bottom: 1px solid #ebedf085;
  margin: 0px 10px;
}
.list {
  position: relative;
  width: 90%;
  margin: 10px 5% 0px;
  background: #fff;
  border-radius: 8px;
  font-size: 14px;
  color: #333;
  box-shadow: 0 0 5px #dadada;
}

.list .title {
  padding: 10px 20px 5px;
  font-weight: 700;
  font-size: 15px;
  color: #000;
  letter-spacing: 2px;
}

.list .flex {
  display: flex;
  font-size: 14px;
  color: #333;
  padding: 10px 20px 5px;
}

.list .flex span:nth-child(1) {
  width: 40%;
}

.list .flex span:nth-child(2) {
  width: 60%;
}

.list .Status {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 16px;
  /* background: #4b8cff; */
  padding: 5px 0px;
  color: #fff;
  width: 62px;
  text-align: center;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
</style>